import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {FaSearch} from 'react-icons/fa';
import Popup from 'reactjs-popup';
import axios from "axios";

const Header = ({setSearchQuery}) => {
    const [popupMessage, setPopupMessage] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [localSearchQuery, setLocalSearchQuery] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, {token});
                    if (response.status === 200 && response.data.status === 'success') {
                        setIsLoggedIn(true);
                    } else {
                        localStorage.removeItem('token');
                        setIsLoggedIn(false);
                    }
                } catch (error) {
                    console.error('Token validation error:', error);
                    localStorage.removeItem('token');
                    setIsLoggedIn(false);
                }
            }
            setLoading(false); // Set loading to false after validation
        };

        validateToken();
    }, []);

    const handleSearch = () => {
        setSearchQuery(localSearchQuery);
    };

    const handleLogout = () => {
        showPopup("Are you sure you want to logout?");
    };

    const showPopup = (message) => {
        setPopupMessage(message);
        setPopupOpen(true);
    };

    return (
        <header className="header">
            <div className="header-top">
                <div className="header-left">
                    <h1 className="pycommerce-title">
                        <a href="/">PyCommerce</a>
                    </h1>
                </div>
                <div className="header-right">
                    <nav className="nav-links">
                        {!loading && (
                            isLoggedIn ? (
                                <>
                                    <Link to="/cart" className="nav-link header-button">Cart</Link>
                                    <Link to="/" onClick={handleLogout} className="nav-link header-button">Logout</Link>
                                </>
                            ) : (
                                <>
                                    <Link to="/login" className="nav-link header-button">Login</Link>
                                    <Link to="/register" className="nav-link header-button">Register</Link>
                                </>
                            )
                        )}
                    </nav>
                </div>
            </div>
            <div className="header-bottom">
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search products..."
                        value={localSearchQuery}
                        onChange={(e) => setLocalSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                    />
                    <button onClick={handleSearch} className="search-button">
                        <FaSearch/>
                    </button>
                </div>
            </div>
            <Popup
                open={popupOpen}
                closeOnDocumentClick
                onClose={() => setPopupOpen(false)}
            >
                <div className="modal">
                    <h2 className="modal-header">Login Status</h2>
                    <div className="modal-content">
                        {popupMessage}
                    </div>
                    <div className="modal-actions">
                        <div className="modal-login-link modal-button">
                            <Link onClick={() => {
                                localStorage.removeItem('token');
                                window.location.reload();
                            }}>Logout</Link>
                        </div>
                    </div>
                </div>
            </Popup>
        </header>
    );
};

export default Header;