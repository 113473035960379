import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import './css/Header.css';
import './css/ProductList.css';
import './css/Pagination.css';
import './css/SearchBar.css';
import './css/Home.css';
import './css/Notification.css'; // Import the CSS file for notification
import Popup from 'reactjs-popup';
import { FaArrowLeft, FaArrowRight, FaCartPlus } from 'react-icons/fa';
import Footer from './components/Footer';
import Header from './components/Header';

const Home = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [popupMessage] = useState('There are no products matching your search.');
    const [popupOpen, setPopupOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const itemsPerPage = 20;

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products`, {
                params: {
                    page: currentPage,
                    limit: itemsPerPage,
                    search: searchQuery
                }
            });
            setData(response.data.products);
            setTotalPages(Math.ceil(response.data.total / itemsPerPage));
            if (currentPage > Math.ceil(response.data.total / itemsPerPage)) {
                setCurrentPage(1);
            }
            setPopupOpen(response.data.products.length === 0);
        } catch (err) {
            setError(err);
            setPopupOpen(true);
        } finally {
            setLoading(false);
        }
    }, [currentPage, searchQuery]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
                if (response.status === 200 && response.data.status === 'success') {
                    setIsLoggedIn(true);
                    return token;
                } else {
                    localStorage.removeItem('token');
                }
            } catch (error) {
                console.error('Token validation error:', error);
                localStorage.removeItem('token');
            }
        }
        return null;
    };

    useEffect(() => {
        validateToken();
    }, []);

    const handleAddToCart = async (productId) => {
        const token = await validateToken();
        if (!token) {
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/cart`, {
                token,
                product_id: productId,
                quantity: 1
            });

            if (response.data.status === 'success') {
                console.log('Product added to cart successfully');
                setNotificationMessage('Item Added');
                setNotificationVisible(true);

                // Add animation class to the button
                const button = document.getElementById(`cart-button-${productId}`);
                if (button) {
                    button.classList.add('cart-button-super-animate');
                    setTimeout(() => {
                        button.classList.remove('cart-button-super-animate');
                    }, 1500);
                }

                // Hide notification after 3 seconds
                setTimeout(() => {
                    setNotificationVisible(false);
                }, 3000);
            } else {
                console.error('Failed to add product to cart:', response.data.message);
            }
        } catch (error) {
            console.error('Error adding product to cart:', error);
        }
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        setCurrentPage(1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 5;
        let startPage, endPage;

        if (window.innerWidth <= 768) {
            if (totalPages <= 3) {
                startPage = 1;
                endPage = totalPages;
            } else {
                startPage = Math.max(1, currentPage - 1);
                endPage = Math.min(totalPages, currentPage + 1);
            }
        } else {
            startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
            endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button key={i} onClick={() => handlePageClick(i)} className={`page-number ${currentPage === i ? 'active' : ''}`}>
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };

    return (
        <div className="home-container">
            <Header
                searchQuery={searchQuery}
                setSearchQuery={handleSearch}
            />
            <div className="main-content">
                {loading ? (
                    <div></div>
                ) : error ? (
                    <div>Error: {error.message}</div>
                ) : (
                    <>
                        {notificationVisible && (
                            <div className={`notification ${notificationVisible ? 'show' : ''}`}>
                                {notificationMessage}
                            </div>
                        )}
                        <Popup
                            open={popupOpen}
                            closeOnDocumentClick
                            onClose={() => setPopupOpen(false)}
                        >
                            <div className="modal">
                                <h2 className="modal-header">No Results Found</h2>
                                <div className="modal-content">
                                    {popupMessage}
                                </div>
                                <div className="modal-actions">
                                    <div className="modal-login-link">
                                        <a href="/">Home</a>
                                    </div>
                                </div>
                            </div>
                        </Popup>
                        <div className="product-list">
                            {data.map(product => (
                                <div key={product.id} className="product-card">
                                    <img src={product.image} alt={product.name} className="product-image" />
                                    <div className="product-details">
                                        <h2 className="product-name">{product.name}</h2>
                                        <p className="product-description">{product.description.length > 100 ? `${product.description.substring(0, 100)}...` : product.description}</p>
                                        <p className="product-price" >${product.price.toFixed(2)}</p>
                                        {isLoggedIn && (
                                            <button
                                                id={`cart-button-${product.id}`}
                                                className="cart-button"
                                                onClick={() => handleAddToCart(product.id)}
                                            >
                                                <FaCartPlus />
                                                Add to Cart
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {totalPages > 1 && (
                            <div className="pagination">
                                <FaArrowLeft
                                    onClick={handlePreviousPage}
                                    className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                                />
                                {renderPageNumbers()}
                                <FaArrowRight
                                    onClick={handleNextPage}
                                    className={`pagination-arrow ${currentPage === totalPages ? 'disabled' : ''}`}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Home;