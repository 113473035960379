import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import './css/Cart.css';
import Footer from './components/Footer';

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customQuantity, setCustomQuantity] = useState({});
    const [showCustomInput, setShowCustomInput] = useState({});
    const [quantityWarning, setQuantityWarning] = useState(null);
    const navigate = useNavigate();

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate_token`, { token });
                if (response.status === 200 && response.data.status === 'success') {
                    return token;
                } else {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Token validation error:', error);
                localStorage.removeItem('token');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
        return null;
    };

    useEffect(() => {
        const fetchCartItems = async () => {
            const token = await validateToken();
            if (!token) return;

            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/cart`, {
                    params: { token }
                });
                setCartItems(response.data.cart);
                const customInputState = {};
                response.data.cart.forEach(item => {
                    if (item.quantity > 10) {
                        customInputState[item.product_id] = true;
                    }
                });
                setShowCustomInput(customInputState);
            } catch (error) {
                console.error('Error fetching cart items:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCartItems();
    }, [navigate]);

    useEffect(() => {
        if (quantityWarning) {
            const timer = setTimeout(() => {
                setQuantityWarning(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [quantityWarning]);

    const handleRemoveItem = async (productId) => {
        const token = await validateToken();
        if (!token) return;

        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/cart`, {
                data: { token, product_id: productId }
            });

            if (response.status === 200 && response.data.status === 'success') {
                setCartItems(cartItems.filter(item => item.product_id !== productId));
            } else {
                console.error('Failed to remove item:', response.data.message);
            }
        } catch (error) {
            console.error('Error removing item:', error);
        }
    };

    const handleUpdateQuantity = async (productId, quantity) => {
        const token = await validateToken();
        if (!token) return;

        const item = cartItems.find(item => item.product_id === productId);
        if (quantity > item.stock) {
            setQuantityWarning({
                message: `This seller has only ${item.stock} of these available.`,
                productId
            });
            quantity = item.stock;
        } else {
            setQuantityWarning(null);
        }

        setCustomQuantity({ ...customQuantity, [productId]: quantity });

        if (quantity === 0) {
            handleRemoveItem(productId);
            return;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/cart`, {
                token, product_id: productId, quantity
            });

            if (response.status === 200 && response.data.status === 'success') {
                setCartItems(cartItems.map(item =>
                    item.product_id === productId ? { ...item, quantity } : item
                ));
                setShowCustomInput({ ...showCustomInput, [productId]: quantity > 10 });
            } else {
                console.error('Failed to update quantity:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating quantity:', error);
        }
    };

    const handleCustomQuantityChange = (productId, value) => {
        setCustomQuantity({ ...customQuantity, [productId]: value });
    };

    const toggleCustomInput = (productId) => {
        setShowCustomInput({ ...showCustomInput, [productId]: !showCustomInput[productId] });
    };

    const renderQuantityOptions = (currentQuantity, productId) => {
        if (showCustomInput[productId]) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', position: 'relative' }}>
                    <input
                        type="number"
                        value={customQuantity[productId] || currentQuantity}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value) && parseInt(value) > 0) {
                                handleCustomQuantityChange(productId, value);
                            }
                        }}
                        id="custom-quantity-input"
                        min="1"
                        step="1"
                        style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            width: '8ch'
                        }}
                    />

                    <button
                        onClick={() => handleUpdateQuantity(productId, parseInt(customQuantity[productId] || currentQuantity))}
                        id="quantity-update-button">
                        <FaEdit />
                    </button>
                    {quantityWarning && quantityWarning.productId === productId && (
                        <div id="quantity-warning" className="tooltip-container">
                            <p className="tooltip-message">{quantityWarning.message}</p>
                            <div className="tooltip-arrow"></div>
                            <button onClick={() => setQuantityWarning(null)} className="tooltip-close">×</button>
                        </div>
                    )}
                </div>
            );
        }

        return (
            <select
                value={currentQuantity > 10 ? "10+" : currentQuantity}
                onChange={(e) => e.target.value === "10+" ? toggleCustomInput(productId) : handleUpdateQuantity(productId, parseInt(e.target.value))}
                id="cart-item-quantity-select"
            >
                {[...Array(10)].map((_, i) => (
                    <option key={i} value={i + 1}>{i + 1}</option>
                ))}
                <option value="10+">10+</option>
            </select>
        );
    };

    const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    if (loading) {
        return <div></div>;
    }

    return (
        <div id="cart-page">
            <h1 id='pycommerce-title'>
                <a href="/">PyCommerce</a>
            </h1>
            <div id="cart-content">
                <h1>Your Cart</h1>
                <div id="cart-container">
                    <div id="cart-main">
                        {cartItems.length === 0 ? (
                            <p>Your cart is empty.</p>
                        ) : (
                            <div id="cart-items">
                                {cartItems.map(item => (
                                    <div key={item.product_id} id="cart-item">
                                        <div id="cart-item-left">
                                            <img src={item.image} alt={item.name} />
                                            <div id="cart-item-details">
                                                <h2 id="cart-item-name">{item.name}</h2>
                                                <div id="cart-item-quantity-wrapper">
                                                    {renderQuantityOptions(item.quantity, item.product_id)}
                                                    <button onClick={() => handleRemoveItem(item.product_id)}
                                                            id="cart-item-remove">
                                                        <FaTrashAlt />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <p id="cart-item-price">${item.price.toFixed(2)}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div id="cart-sidebar">
                        <div id="summary-content">
                            <p>Subtotal ({cartItems.reduce((acc, item) => acc + item.quantity, 0)} items): <strong>${subtotal}</strong></p>
                            <button id="checkout-button">Proceed to Checkout</button>
                        </div>
                    </div>
                </div>
                <Link to="/" id="continue-shopping">Continue Shopping</Link>
            </div>
            <Footer />
        </div>
    );
};

export default Cart;