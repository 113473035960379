import React from 'react';

import {HashRouter as Router, Route, Routes} from 'react-router-dom';


import Register from './pages/Register';
import Home from './pages/Home';
import Cart from './pages/Cart';
import Login from './pages/Login';

import './App.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/cart" element={<Cart/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/login" element={<Login/>}/>
            </Routes>
        </Router>
    );
}

export default App;